// Import the SimplyBizElement base class and helpers
import { html, css } from 'lit-element';
import { SimplyBizElement } from './base-component';

// Extend the SimplyBizElement base class
class SimplyBizFooter extends SimplyBizElement {
 
    static get properties() { return { 
        logo: { attribute: 'logo' }, 
        links: { type: Array }, 
      };}
      

      constructor() {
        super();
        this.logo = ''; 
        this.links = new Array();
      }

    static get styles() {

        return [
            super.styles,
            css`:host {
                background-color: #fff;
                border-top: 1px solid #ddd;
                min-height: 40px;
                padding: 0 20px;
                bottom: 0;
                display: flex;
                align-items: center;
                flex-direction: column-reverse;
            } 
            @media (min-width: 768px) {
                :host {
                    flex-direction: row;
                }
            }`,
            css`logo {
                margin-right: auto;
            }`,
            css`footer-link {
                font-size: 1rem;
                margin-right: auto;
                padding-bottom: 1.25rem;
            }
    
            footer-link:hover {
                text-decoration: underline;
            }
    
            footer-link:last-of-type {
                padding-top: 1rem;
            }
    
            footer-link > * {
                color: #666666 !important;
            }
    
            @media (min-width: 768px) {
                footer-link {
                    padding-left: 1rem;
                    font-size: 14px;
                    margin-right: 0;
                    padding-bottom: 0;
                }
    
                footer-link:last-of-type {
                    padding-top: 0;
                }
    
                footer-link:not(:last-of-type) {
                    padding-right: 1rem;
                    border-right: 1px solid #eeeeee;
                }
            }`,

        ];
    }

    render() {
        return html`
        ${this.footerLogoTemplate} 
        ${this.footerLinksTemplate}
        `;
    }

    get footerLogoTemplate() {
        return html`
        <logo>  
            <img src="${this.logo}" alt="Powered By Nedbank"/>
        </logo>`;
    }

    get footerLinksTemplate() { 
        return html`${this.links.map(link => html`<footer-link><a href="${link.value}">${link.key}</a></footer-link>`)}`;
    }
     
}

// Register the new element with the browser.
customElements.define('simplybiz-footer', SimplyBizFooter);