// Import the LitElement base class and html helper function
import { LitElement, html, css } from 'lit-element';

// Extend the LitElement base class to create our own
export class SimplyBizElement extends LitElement {
    static get styles() {
                   
        return [
            css`h1,
            h2,
            h3,
            h4,
            h5,
            h6,
            p {
                margin-bottom: 15px;
                padding: 0;
                font-weight: normal;
                color: #333;
                line-height: 1.25;
                font-family: "FFMarkWebProRegular", Helvetica, sans-serif;
                margin-bottom: 15px
            }
    
            @media screen and (max-width: 768px) {
    
                h1,
                h2,
                h3,
                h4,
                h5,
                h6,
                p {
                    margin-bottom: 10px
                }
            }
    
            @media screen and (max-width: 640px) {
    
                h1,
                h2,
                h3,
                h4,
                h5,
                h6,
                p {
                    margin-bottom: 10px
                }
            }
    
            h1 {
                font-size: 3.75rem;
                text-transform: capitalize;
            }
    
            @media screen and (max-width: 768px) {
                h1 {
                    font-size: 2.5rem;
                }
            }
    
            @media screen and (max-width: 640px) {
                h1 {
                    font-size: 1.875rem;
                }
            }
    
            h2 {
                font-size: 2.5rem;
            }
    
            @media screen and (max-width: 768px) {
                h2 {
                    font-size: 1.875rem
                }
            }
    
            @media screen and (max-width: 640px) {
                h2 {
                    font-size: 1.625rem
                }
            }
    
            h3 {
                font-size: 1.875rem;
            }
    
            @media screen and (max-width: 768px) {
                h3 {
                    font-size: 1.625rem
                }
            }
    
            @media screen and (max-width: 640px) {
                h3 {
                    font-size: 1.5rem
                }
            }
    
            h4 {
                font-size: 1.75rem;
            }
    
            @media screen and (max-width: 768px) {
                h4 {
                    font-size: 1.5rem
                }
            }
    
            @media screen and (max-width: 640px) {
                h4 {
                    font-size: 1.375rem
                }
            }
    
            h5 {
                font-size: 1.375rem;
            }
    
            @media screen and (max-width: 768px) {
                h5 {
                    font-size: 1.375rem
                }
            }
    
            @media screen and (max-width: 640px) {
                h5 {
                    font-size: 1.25rem
                }
            }
    
            h6 {
                font-size: 1.25rem
            }
    
            @media screen and (max-width: 768px) {
                h6 {
                    font-size: 1.25rem
                }
            }
    
            @media screen and (max-width: 640px) {
                h6 {
                    font-size: 1.125rem
                }
            }`,
            css` p {
                font-size: 1rem;
                line-height: 1.5
            }`,
            css`a {

                text-decoration: none;
            }`,
            

        ];
    }
}

